* {
  font-family: acumin-pro, sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  letter-spacing: 2px;
}

.funds-contain{
  margin-top: 5vh;
}

.cash-fund{
  font-size: 9vw;
  letter-spacing: 10px;
  text-align: center;
  margin: 0;
  font-family: acumin-pro, sans-serif;
}
.under-line{
  background: #FFD669;
  width: 50vw;
  height: 1vw;
  margin: 0 25vw 0 25vw;
}
.funds-bottom{
  height: 75%;
  display: flex;
  margin-top: 9%;
}
.side-bar{
  width: 25%;
  height: 100vh;
  flex: 1;
  padding: 3%;
  position: sticky;
}
.search-funds input[type]{
  width: 19vw !important;
  border: solid #FFD669 2px !important;
  border-radius: 0 !important;
  font-size: 1vw;
}

.all-funds-contain{
  width: 75%;
  height:auto;
  flex: 5;
  display: flex;
  flex-wrap: wrap;
}
.single-fund-contain-box{
  border:solid #FFD669 3px;
  height: 420px;
  width: 320px;
  margin: 1.66%;
  position: relative;
}
.add-funds-box{
  border:solid #FFD669 3px;
  height: 420px;
  width: 320px;
  margin: 1.66%;
  position: relative;
}

.fund-add{
  position: absolute !important;
  left: 235px;
  top: 15px;
}

.create-fund-button{
  background: white !important;
  position: absolute;
  color: black !important;
  width: 70% !important;
  top: 350px;
  left: 50px;
  border: solid #FFD669 2px !important;
}

.funds-form{
  border: solid #FFD669 2px;
  height: auto;
  width: 80vw;
  position: absolute !important;
  top: 30%;
  left: 15%;
  background: white;
  padding: 2% 3% 2% 3%;
}

.fund-form-contain{
  display: flex !important;
}

.funds-form-left{
  flex: 2;
  padding-top: 2%;
}
.funds-form-right{
  flex: 5;
  padding: 2% 2% 0 2%;
}
.funds-form-header{
  margin: 0 0 2% 0;
  padding: 0;
  font-size: 1.7vw;
}
.ff-learn-more{
  margin: 0 0 2% 0;
  padding: 0;
  font-size: 1vw;
}
.funds-form-sub-head{
  margin:0;
  padding:0;
  font-size: 1vw;
}
.fund-image{
  width: 100%;
  height: 27vw;
  border: solid #707070 1px;
}
.change-fund-image{
  background: #FFD669 !important;
  color: white !important;
  padding: 5% 7% 5% 7% !important;
  font-size: 0.9vw !important;
  width: 60%;
  margin: 8% 0 0 20% !important;
}
.fund-form-button{
  width: 15vw;
  background: #FFD669 !important;
  color: white !important;
  padding: 2% 3% 2% 3% !important;
  font-size: .8vw !important;
}
.fund-form-input input[type]{
  border: solid #FFD669 1px !important;
  border-radius: 0 !important;
  margin-top: 1% !important;
}
.fund-form-input{
  width: 55% !important;
  font-size: 1vw;
}
.label-size{
  font-size: 1vw !important;
  padding:0 2% 0 2%;
  margin: 0 !important;
  width: 100%;
}
.label-size-1{
  font-size: 1vw !important;
  padding: 0 2% 0 2%;
  width: 100%;
}
.field-flex{
  display: flex !important;
  margin: 0 !important;
}
.fund-form-textArea {
  border: solid #FFD669 1px !important;
  border-radius: 0 !important;
  margin: 1% 0 2% 0 !important;
  font-size: 1vw !important;
}
