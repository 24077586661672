.coming-soon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
}

.coming-soon-message {
    text-align: center;
    color: #333;
}

.coming-soon-message h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.coming-soon-message p {
    font-size: 1.5rem;
    color: #666;
}

.coming-soon-jpg{
    width: 100vw;
    height: 100vh;
    object-fit: fills;
    object-position: center;
}