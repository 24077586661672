* {
  font-family: acumin-pro, sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  letter-spacing: 2px;
}

/* 16vh = 125px = 7.8vw */
/*-----------------<NavBar />--------------*/
nav {
  height: auto;
  padding-top: 15px;
  width: 100vw;
  display: flex !important;
}

.nav-contain {
  width: 75%;
  display: flex;
  justify-content: center;
}


.logo-link {
  width: 12.5%;
  padding: 1.2% 0 0 0.5%;
}

.logo {
  width: 100%;
}

.router-links {
  color: black;
  margin-bottom: 4%;
  font-size: 1.3vw;
}
.cata-header{
  color: black;
  margin-bottom: 4%;
  font-size: 1vw;
}

.nav-animation-contain {
  text-align: center;
  height: 100%;
  padding: 1% 0 1% 0;
  margin: 0 3% 0 3%;
  width: 10%;
  overflow: hidden;
}

.swiggle-style {
  position: relative;
  width: calc(100% + 50px);
  left: 0px;
}

.registry:hover .registry-swiggle {
  animation: wave .5s linear infinite;
}

.admin-collection:hover .admin-collection-swiggle {
  animation: wave .4s linear infinite;
}

.sign-in {
  margin: 0;
  text-align: center;
  padding-top: 1.5%;
  width: 12.5%;
}

.signup-button {
  background-color: #FFD669;
  color: white;
  border: none;

  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-button:hover {
  background-color: #0056b3;
}


.sign-up {
  margin-top: 0.75%;


align-items: center;
width: 10%;

  background-color: #ffd669;
  border-radius: 20px;
  max-lines: 1;
padding-left: 20px;
padding-right: 20px;
  text-align: center;
  
 

}

.create-account{
  background-color: #ffd669;
  
  
}


.invite:hover .invite-swiggle {
  animation: wave .5s linear infinite;
}

@keyframes wave {
  0% {
    left: 0px;
  }

  100% {
    left: -50px;
  }
}

/*-----------------<SIGNIN />--------------*/
.sign-in {
  text-align: center;
  width: 12.5%;
  font-size: 1.5vw;
}

.modal-div {
  border: solid #FFD669 5px;
  padding: 2% 0 2% 0;
  height: 100%;
}

.welcome-header {
  text-align: center;
  font-size: 3vw;
  height: 20%;
  margin: 0;
  padding-top: 3%;
  letter-spacing: 4px;
}

.modal-form-contain {
  height: 80%;
  padding: 5% 21% 2% 21%;
}

.modal-input input[type] {
  border: solid #FFD669 !important;
  margin-top: 3% !important;
  font-size: 1.5vw !important;
}

.sign-in-button {
  padding: 2% 15% 2% 15% !important;
  font-size: 1.5vw !important;
  margin: 15% 0 0 0 !important;
  background: #FFD669 !important;
  color: white !important;
}

/*-----------------<DATA />--------------*/
.form-data {
  display: flex;
}

.form {
  width: 100%;
}

.weight {
  padding: 0 !important;
}

.sizing {
  width: 25%;
}

.my-themes {
  margin-top: 2% !important;
}

.sizing-half {
  width: 50%;
}

.contain {
  display: flex;
  padding: 2vh 0 5vh 0;
}

.left {
  flex: 1;
  padding: 5vh 5vw 5vh 5vw;
}

.right {
  padding: 5vh 5vw 5vh 3vw;
  flex: 1;
}

.file-upload {
  margin: 2vh 0 0 0 !important;
}

.img-style {
  border: solid black 1px;
  padding: 2vh 2vw 2vh 2vw;
  text-align: center;
}

.sections {
  margin-top: 6vh;
}

.lable {
  padding-left: 4vw;
}

.color-style {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 0 30px 20px 0;
}

.color-style:hover, .sign-in:hover {
  cursor: pointer;
}

.color-selector {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
}

.palette {
  margin-top: 15vh;
}

.add-button-contain {
  display: flex;
  margin-bottom: 2vh;
}

.add-title {
  margin: 0 0 0 1vw;
  padding-top: 1.5%;
}

.currency-margin-left {
  margin-left: 5rem !important;
}

/*-----------------<Home />--------------*/
.home-contain {
  text-align: center;
  height: auto;
}

.home-top-section {
  height: 20%;
  padding-top: 10%;

}

.options-contain {
  height: auto;
  padding: 1% 0 0 0;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding-left: 5%;
}

.sample-display {
  height: auto;
}


.headers {
  border-bottom: solid #617F7D 5px;
  line-height: 1.5;
  font-size: 4.5vw;
  width: 50%;
  margin: auto;
  padding-bottom: 2%;
  font-weight: 700;
}

.start-here {
  width: 20%;
  font-size: 2.5vw;
  margin-left: calc(50% - 10%);
}

.start-here:hover {
  cursor: pointer;
  text-shadow: 3px 3px darkgrey;
}

/* StartHere-Form-Styles */
.start-here-form-1 {
  border: solid #FFD669 5px;
}

.start-here-form-2 {
  border: solid #FFD669 5px;
}

.start-here-form-3 {
  border: solid #FFD669 5px;
}

.step-style {
  padding-right: 5%;
  margin-bottom: 3%;
  index: 2;
  font-size: 2.5vw;
}

.step-style::before {
  index: 1;
  border: solid 3px #FFD669;
}

.start-here-form-style {
  width: 72%;
  height: 52%;
  margin: 0 14% 0 14%;
  padding: 3% 5% 7% 5%;
  border: solid #FFD669 3px;
}

.start-here-form-style-2 {
  width: 80%;
  margin: 0 10% 5% 10%;
  padding: 2% 5% 2% 5%;
  border: solid #FFD669 3px;
}

.start-here-modal-input input[type] {
  border: solid #FFD669 !important;
  margin-top: 2% !important;
  font-size: 1.2vw !important;
  border-radius: 50px !important;
}

.start-here-modal-input-2 input[type] {
  border: solid #FFD669 !important;
  margin-top: 2% !important;
  font-size: 1.1vw !important;
  border-radius: 50px !important;
}
.div-padding{
  padding: 0% 50% 0 2%;
  margin: 0 0 3% 0;
}

.form-group {
  display: flex;
  margin-bottom: 3%;
}

.next-button-1 {
  border: solid #FFD669 !important;
  border-radius: 50px !important;
  background: white !important;
  font-size: 1.2vw !important;
  margin: 0 1% 0 1% !important;
  color: black !important;
}

.next-button-2 {
  border: solid #FFD669 !important;
  border-radius: 50px !important;
  background: white !important;
  font-size: 1vw !important;
  margin: 0 1% 0 1% !important;
  color: black !important;
}

.start-here-button-contain {
  text-align: center;
  margin-top: 3%;
}

.start-here-button-contain-2 {
  text-align: right;
  margin-top: 3%;
}

/* StartHere-Form-Styles */
.find-registry {
  font-size: 2vw !important;
  letter-spacing: 3px !important;
  background: #FFD669 !important;
  color: white !important;
  margin: 8% 0 1% 0 !important;
  padding: 1% 0 1% 0 !important;
  width: 30%;
}

.find-registry-header {
  text-align: center;
  align-content: center;
  font-size: 3vw;
  height: 20%;
  margin: 0;
  padding: 2% 0 2% 0;
  letter-spacing: 5px;
}

.registry-modal-style {
  padding: 1% 21% 2% 21%;
}

.find-registry-submit {
  padding: 2% 15% 2% 15% !important;
  font-size: 1.6vw !important;
  margin: 5% 0 0 0% !important;
  background: #FFD669 !important;
  color: white !important;
}

.swiggle-line {
  position: relative;
  height: 100%;
  width: calc(100% + 20vw);
  left: -1vw;
}

.swigg1:hover {
  animation: wave2 1s linear infinite;
}

.swiggle-contain {
  position: relative;
  margin: 3% 1% 0% 1%;
  overflow: hidden;
}

@keyframes wave2 {
  0% {
    left: -1vw;
  }

  100% {
    left: -20vw;
  }
}

.options-sub-contain {
  width: 24vw;
  height: 24vw;
  margin: 3%;
  position: relative;
}

.options {
  background: rgb(255, 199, 148);
  height: 18vw;
  width: 18vw;
  border-radius: 50%;
  text-align: center;
  font-size: 2vw;
  color: black;
  padding: 45% 20% 45% 20%;
}

.option-link {
  top: calc(50% - 9vw);
  left: calc(50% - 9vw);
  position: absolute;
  z-index: 4;
}

/*---------<Home /> animations----------*/
.shapes {
  height: 18vw;
  width: 18vw;
  position: absolute;
  top: 0;
  left: 0;
}

.index2 {
  z-index: 3 !important;
}

.index1 {
  z-index: 2 !important;
}

.toy-anime-2 {
  top: 8vw;
  left: 7.5vw;
}

.toy:hover .toy-anime-1 {
  animation: diagonal_down .2s ease-in-out forwards;
}

.toy:hover .toy-anime-2 {
  animation: spin_diagonal .2s ease-in-out forwards;
}

.toy:hover .opt-toy {
  background: rgba(255, 199, 148, .8);
}

@keyframes spin_diagonal {
  0% {
    top: 8vw;
    left: 7.5vw;
  }

  100% {
    top: 5.3vw;
    left: 4.4vw;
    transform: rotate(-10deg);
  }
}

@keyframes diagonal_down {
  0% {
    top: 0;
    left: 0;
  }

  100% {
    top: .8vw;
    left: .8vw;
  }
}

.fund-anime-1 {
  top: -1.3vw;
  left: 7.5vw;
}

.fund-anime-2 {
  top: 8.5vw;
}

.fund:hover .fund-anime-1 {
  animation: diagonal_in .2s ease-in-out forwards;
}

.fund:hover .fund-anime-2 {
  animation: up_in .2s ease-in-out forwards;
}

.fund:hover .opt-fund {
  background: rgba(255, 199, 148, .8);
}

@keyframes diagonal_in {
  0% {
    top: -1.3vw;
    left: 7.5vw;
  }

  100% {
    top: 1.3vw;
    left: 5.6vw;
  }
}

@keyframes up_in {
  0% {
    left: 0;
    top: 8.5vw;
  }

  100% {
    left: .6vw;
    top: 5.6vw;
    transform: rotate(-30deg);
  }
}

.ess-anime-1 {
  top: 7.7vh;
  height: 23vw;
  width: 23vw;
}

.ess-anime-2 {
  height: 16vw;
  width: 17vw;
  background: rgb(235, 212, 189);
  top: 6.7vw;
  left: 6vw;
  z-index: 0 !important;
  position: absolute;
}

.essential:hover .ess-anime-2 {
  animation: diagonal_up .2s ease-in-out forwards;
}

.essential:hover .ess-anime-1 {
  animation: rotate .2s ease-in-out forwards;
}

.essential:hover .opt-ess {
  background: rgba(255, 199, 148, .8);
}

@keyframes diagonal_up {
  0% {
    top: 6.7vw;
    left: 6vw;
  }

  100% {
    top: 5vw;
    left: 3vw;
  }
}

@keyframes rotate {
  0% {
    top: 7.7vh;
  }

  100% {
    transform: rotate(-40deg);
    top: .6vw;
    left: .6vw;
  }
}

.class-anime-1 {
  left: 5vw;
  height: 20vw;
  width: 20vw;
  transform: rotate(20deg);
}

.class-anime-2 {
  background: #CD895E;
  height: 14vw;
  width: 14vw;
  border-radius: 50%;
  top: 9.5vw;
  left: 1.5vw;
}

.class:hover .class-anime-1 {
  animation: rotate_in .2s ease-in-out forwards;
}

.class:hover .class-anime-2 {
  animation: up .2s ease-in-out forwards;
}

.class:hover .opt-class {
  background: rgba(255, 199, 148, .8);
}

@keyframes up {
  0% {
    top: 9.5vw;
  }

  100% {
    top: 6.5vw;
  }
}

@keyframes rotate_in {
  0% {
    transform: rotate(20deg);
    left: 5vw;
  }

  100% {
    transform: rotate(-30deg);
    left: 3vw;
  }
}

.game-anime-1 {
  top: -.7vw;
  left: -.7vw;
}

.game-anime-2 {
  top: 6vw;
  left: 6vw;
  height: 19.5vw;
  width: 19.5vw;
}

.game:hover .game-anime-1 {
  animation: diagonal_down_2 .2s ease-in-out forwards;
}

.game:hover .game-anime-2 {
  animation: diagonal_up_2 .2s ease-in-out forwards;
}

.game:hover .opt-game {
  background: rgba(255, 199, 148, .8);
}

@keyframes diagonal_down_2 {
  0% {
    top: -.7vw;
    left: -.7vw;
  }

  100% {
    top: 1.3vw;
    left: 1.3vw;
    transform: rotate(20deg);
  }
}

@keyframes diagonal_up_2 {
  0% {
    top: 6vw;
    left: 6vw;
  }

  100% {
    top: 4.5vw;
    left: 4.5vw;
  }
}

.don-anime-1 {
  left: 7vw;
}

.don-anime-2 {
  top: 7vw;
}

.donate:hover .don-anime-1 {
  animation: diagonal_spin_in .2s ease-in-out forwards;
}

.donate:hover .don-anime-2 {
  animation: diagonal_left .2s ease-in-out forwards;
}

.donate:hover .opt-donate {
  background: rgba(255, 199, 148, .8);
}

@keyframes diagonal_left {
  0% {
    top: 7vw;
  }

  100% {
    top: 5vw;
    left: 2vw;
  }
}

@keyframes diagonal_spin_in {
  0% {
    left: 7vw;
  }

  100% {
    top: 1.5vw;
    transform: rotate(30deg);
  }
}

.dots {
  z-index: 1 !important;
  height: 20px;
  width: 20px;
  position: absolute;
}

.dot-animate1 {
  animation: spin 2s linear infinite;
}

.dot-animate2 {
  animation: spin2 2s linear infinite;
}

.dot-animate3 {
  animation: spin 3s linear infinite;
}

.dot-animate4 {
  animation: spin2 3s linear infinite;
}

.dot-animate5 {
  animation: spin 4s linear infinite;
}

.dot-animate6 {
  animation: spin2 4s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg) translate(-15px) rotate(0deg);
  }

  to {
    transform: rotate(-360deg) translate(-15px) rotate(-360deg);
  }
}

@keyframes spin2 {
  from {
    transform: rotate(0deg) translate(15px) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translate(15px) rotate(360deg);
  }
}

.divide {
  width: 100%;
  height: 18vw;
  background: url('./assets/HomePage/split.png');
  background-position: contain;
  background-repeat: no-repeat;
  margin: 0 !important;
  padding: 0;
}

.example {
  display: flex;
  flex: 2;
  background: #4E615D;
  height: auto;
  margin: 0 !important;
  position: relative;
}

.example-left {
  height: 100%;
  width: 100%;
  position: relative;
}

.example-right {
  height: 100%;
  width: 100%;
  padding: 21vw 0 21vw 0;
}

.example-temp {
  position: absolute;
  left: calc(50% - 33.5%);
  width: 32.5vw;
  z-index: 5;
}

.example-circle {
  background: rgba(255, 199, 148, 0.6);
  border-radius: 50%;
}

.examp-1 {
  left: 3%;
  height: 40vw;
  width: 40vw;
  position: absolute;
}

.examp-2 {
  top: 3vw;
  left: 30%;
  height: 38vw;
  width: 38vw;
  z-index: 4;
  position: absolute;
}

.examp-3 {
  top: 13vw;
  left: 20%;
  height: 35vw;
  width: 35vw;
  z-index: 3;
  position: absolute;
}

.invite-button {
  font-size: 1.9vw !important;
  letter-spacing: 2px !important;
  line-height: 1.3 !important;
  padding: 1vh 5vw 1vh 5vw !important;
  background: #FFD669 !important;
  color: white !important;
  width: 70%;
}

.invite-text {
  margin: 4% 5% 0 5%;
  font-size: 1.25vw;
}

.swiggle-contain-2 {
  position: relative;
  margin: 0 1% 0 1%;
  overflow: hidden;
  transform: translateY(-2.9vw);
  z-index: 4 !important;
}

.swigg2:hover {
  animation: wave2 1s linear infinite;
}

/* --------------<Categories />------------------- */
.categories-nav {
  display: flex;
  width: 100%;
  height: auto;
}

.cata-links {
  text-align: center;
  padding-top: 1%;
  margin: 0 3% 0 3%;
  overflow: hidden;
}

.cata-swiggle {
  position: relative;
  width: calc(100% + 65px);
  left: -10px;
}

.cata-toys:hover .cata-toys-swiggle {
  animation: wave-cata .5s linear infinite;
}

.cata-funds:hover .cata-funds-swiggle {
  animation: wave-cata .5s linear infinite;
}

.cata-essentials:hover .cata-essentials-swiggle {
  animation: wave-cata .5s linear infinite;
}

.cata-classes:hover .cata-classes-swiggle {
  animation: wave-cata .5s linear infinite;
}

.cata-games:hover .cata-games-swiggle {
  animation: wave-cata .5s linear infinite;
}

.cata-donate:hover .cata-donate-swiggle {
  animation: wave-cata .5s linear infinite;
}

.cata-subscription:hover .cata-subscription-swiggle {
  animation: wave-cata .5s linear infinite;
}

@keyframes wave-cata {
  0% {
    left: -5px;
  }

  100% {
    left: -60px;
  }
}

/* --------------footer------------------- */
footer {
  height: 45vh;
  background-color: rgba(255, 214, 105, 0.1);
}

/*---------<Admin /> ----------*/
.wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 3rem;
  margin: 6rem 9rem;
}
.cell{
  border: 1px solid rgba(255, 214, 105, 1);
}
.admin-contain{
  text-align: center;
}

.admin-top-section {
  margin-top: 5vh;
}

.admin-heading {
  line-height: 1.5;
  font-size: 9.5rem;
  margin: auto;
}

.admin-collections {
  display: flex;
  justify-content: center;
  padding: 2vh 0 2vh 0;
}

.admin-content-container {
  width: 85%;
  height: 150vh;
  margin: 3rem auto 2rem auto;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  flex-basis: 100%;
  box-sizing: border-box;
  margin: 40px;
}

.img-overlay {
  position: relative;
  width: 40px;
  height: 40px;
}

.img-overlay.circle {
  top: 5%;
  left: 5%;
  border: 1px solid black;
  border-radius: 50%;
}

.img-overlay.plus{
  position: relative;
  top: -1%;
  left: 80%;
  border-radius: 50%;
}
.img-overlay.minus{
  top: 3%;
  left: 80%;
  border-radius: 50%;
}
.vertical-center{
  position: relative;
  top: 25%;
  left: 1%;
  margin-bottom: 0px;
}

.clearfix {
  overflow: hidden;
}

.img-overlay-pointer {
  cursor: pointer;
}


.plus {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 25%;
  /* margin-right: 20px;
  margin-top: -15px; */
}

.minus {
  position: absolute;
  width: 40px;
  height: 40px;
  /* float: right;
  margin-right: 20px;
  margin-top: 0px; */
  /* top: calc(5% + 50px);
  left: 82%; */
}

@media screen and (min-width: 800px) {

  .column {
    flex: 1;
  }
}

.admin-collection-image {
  width: 250px;
  height: auto;
  padding: 1.2rem;
}

li {
  list-style: none;
}

footer {
  height: auto;
}

.footer-section {
  display: flex;
  justify-content: flex-end;
  padding: 2% 0 2% 0;
}

.footer-list {
  width: 22%;
  margin-right: 2%;
}

.list-style1 {
  font-size: .8vw;
  margin-top: 7%;
}

.list-style1:hover {
  cursor: pointer;
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  width: 100vw;
  padding: 1% 0 1% 0;
}

.footer-bottom-left {
  display: flex;
  width: 16vw;
  padding-left: 1%;
  margin: 1% 0 0 0;
}

.rights-reserved {
  font-size: .8vw;
}

.footer-bottom-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 84vw;
}

.list-style2 {
  text-align: center;
  width: 240px;
  font-size: .75vw;
  margin: 1% 0 0 0;
  border-right: solid black 1px;
}
.list-style2:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* Example CSS media query */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .list-style1 {
    height: 5vh;
    margin-top: 0%;
    font-size: 2vw;
    background-color: rgba(255, 214, 105, 0.1);
  }
  .footer-list {
    width: 50%; 
  }
}
